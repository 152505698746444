<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12>
        <ValidationProvider name="Name" rules="required" immediate>
          <v-text-field
            v-model="name"
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            label="Name"
            hint="Name of command."
            clearable
            required
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs12>
        <ValidationProvider name="Description" rules="required" immediate>
          <v-textarea
            v-model="description"
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            label="Description"
            hint="Description of command."
            clearable
            required
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs12>
        <project-select v-model="project" />
      </v-flex>
      <v-flex xs12>
        <tag-filter-auto-complete
          label="Tags"
          v-model="tags"
          :project="project"
          model="command"
          :model-id="id"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { ValidationProvider, extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"

import TagFilterAutoComplete from "@/tag/TagFilterAutoComplete.vue"
import ProjectSelect from "@/project/ProjectSelect.vue"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "CommandDetailsTab",

  components: {
    ValidationProvider,
    TagFilterAutoComplete,
    ProjectSelect,
  },

  computed: {
    ...mapFields("command", [
      "selected.id",
      "selected.name",
      "selected.description",
      "selected.project",
      "selected.tags",
      "selected.loading",
    ]),
  },

  data() {
    return {
      editorOptions: {
        automaticLayout: true,
        renderValidationDecorations: "on",
      },
    }
  },
}
</script>
