<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12>
        <v-select :items="languages" v-model="language" label="Language"></v-select>
      </v-flex>
      <v-flex xs12>
        <div style="height: 100vh">
          <MonacoEditor v-model="command_text" :options="editorOptions" :language="language"></MonacoEditor>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"

export default {
  name: "CommandTextTab",

  components: {
    MonacoEditor: () => import("monaco-editor-vue"),
  },

  computed: {
    ...mapFields("command", ["selected.command_text", "selected.language", "selected.loading"]),
  },

  data() {
    return {
      languages: ["bash", "python", "javascript", "typescript", "nodejs", "go", "java", "c", "cpp", "csharp", "rust", "ruby", "perl", "php", "html", "css", "json", "yaml", "xml", "markdown", "plaintext"],
      editorOptions: {
        automaticLayout: true,
        renderValidationDecorations: "on",
      },
    }
  },
}
</script>
